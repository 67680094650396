import { createApp } from 'vue'
import { createI18n } from 'vue-i18n';
import App from './App.vue'
import router from './router'

const i18n = createI18n({
  locale: 'nl',
  fallbackLocale: 'nl',
  missingWarn: false,
  fallbackWarn: false,
  legacy: false
});

createApp(App)
  .use(router)
  .use(i18n)
  .mount('#app');