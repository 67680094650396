export enum OptInKeuze {
  Ja = "Ja",
  Nee = "Nee",
  Onbekend = "Onbekend",
}
export enum RelatieType {
  Particulier = "Particulier",
  Zakelijk = "Zakelijk",
  Leasemaatschappij = "Leasemaatschappij",
}
export interface PermissieViewModel {
  Messenger: OptInKeuze;
  Email: OptInKeuze;
}

export interface GroepViewModel {
  Auto: PermissieViewModel;
  MerkModel: PermissieViewModel;
  Bedrijf: PermissieViewModel;
  Overig: PermissieViewModel;
  Enquetes: PermissieViewModel;
}

export interface OptInViewModel {
  Autobedrijf: GroepViewModel;
}

export interface RelatieViewModel {
  RelatieType: RelatieType;
  Optin: OptInViewModel;
  NietBenaderen: boolean|null;
}
