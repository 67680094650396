import { OptInKeuze } from "./TypeScriptModels";

export default {
  Autobedrijf: {
    Auto: {
      Messenger: OptInKeuze.Onbekend,
      Email: OptInKeuze.Onbekend
    },
    MerkModel: {
      Messenger: OptInKeuze.Onbekend,
      Email: OptInKeuze.Onbekend
    },
    Bedrijf: {
      Messenger: OptInKeuze.Onbekend,
      Email: OptInKeuze.Onbekend
    },
    Overig: {
      Messenger: OptInKeuze.Onbekend,
      Email: OptInKeuze.Onbekend
    },
    Enquetes: {
      Messenger: OptInKeuze.Onbekend,
      Email: OptInKeuze.Onbekend
    }
  },
  BedrijfsGroep: {
    Auto: {
      Messenger: OptInKeuze.Onbekend,
      Email: OptInKeuze.Onbekend
    },
    MerkModel: {
      Messenger: OptInKeuze.Onbekend,
      Email: OptInKeuze.Onbekend
    },
    Bedrijf: {
      Messenger: OptInKeuze.Onbekend,
      Email: OptInKeuze.Onbekend
    },
    Overig: {
      Messenger: OptInKeuze.Onbekend,
      Email: OptInKeuze.Onbekend
    },
    Enquetes: {
      Messenger: OptInKeuze.Onbekend,
      Email: OptInKeuze.Onbekend
    }
  }
};
